<template>
  <b-overlay :show="isFetchingCmsSite">
    <b-row>
      <b-col class="text-right mb-2">
        <b-button
          variant="success"
          @click="$bvModal.show('cms-manage-floating-ads')"
          >New Floating Ads</b-button
        >
      </b-col>
    </b-row>
    <b-table :fields="fields" :items="cmsFloatingAds" responsive>
      <template #cell(#)="data">
        <div>
          {{ data.index + 1 }}
        </div>
      </template>
      <template #cell(imageSrc)="data">
        <b-img
          v-if="data.value"
          :src="data.value"
          width="auto"
          height="150px"
        ></b-img>
        <div v-else>-</div>
      </template>
      <template #cell(floatingPosition)="data">
        <div>
          <b-badge variant="info">{{ data.value }}</b-badge>
        </div>
      </template>
      <template #cell(isExternalLink)="data">
        <b-badge v-if="data.value" variant="success">Yes</b-badge>
        <b-badge v-else variant="danger">No</b-badge>
      </template>
      <template #cell(externalLinkTo)="data">
        <div>
          {{ data.value || '-' }}
        </div>
      </template>
      <template #cell(internalLinkTo)="data">
        <div>
          {{ data.value || '-' }}
        </div>
      </template>
      <template #cell(id)="data">
        <div>
          <b-button
            size="sm"
            variant="danger"
            @click="onRemoveFloatingAds(data.value)"
            >Remove</b-button
          >
        </div>
      </template>
    </b-table>
    <CmsManageFloatingAds />
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    CmsManageFloatingAds: () => import('./manage-floating-ads.vue'),
  },
  data() {
    return {
      fields: [
        '#',
        {
          key: 'imageSrc',
          label: 'Image',
        },
        {
          key: 'floatingPosition',
          label: 'Floating Position',
        },
        {
          key: 'isExternalLink',
          label: 'External Link',
        },
        {
          key: 'internalLinkTo',
          label: 'Internal Link',
        },
        {
          key: 'externalLinkTo',
          label: 'External Link',
        },
        {
          key: 'id',
          label: 'Actions',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['cmsFloatingAds', 'siteId']),
    ...mapState({
      isFetchingCmsSite: (state) => state.cms.isFetchingCmsSite,
    }),
  },
  methods: {
    ...mapActions(['removeCmsFloatingAds']),
    onRemoveFloatingAds(floatingAdsId) {
      this.removeCmsFloatingAds({ siteId: this.siteId, floatingAdsId })
    },
  },
}
</script>
